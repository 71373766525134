:root {
  --primary-color: #222222;
  --secondary-color: #002642;
  /* color: #0067b1; */
  --text-primary: #ccff00;
  --text-seconary: #f2f2f2;
  --line-space: 23px;
}

body {
  /* background-color: var(--primary-color); */

  color: white;
}

/* Loader.jsx */
.loader-con {
  width: 70px;
  stroke: var(--text-primary);
}

/* Home loader */
.home-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-color);
  backdrop-filter: blur(5px);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* BackgroundDesign.jsx */
.background-con {
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to top, var(--secondary-color), black);
}

.bg-box {
  flex: 1;
  border: 0.1px solid var(--secondary-color);
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.bg-box1 {
  height: 100vh;
  border: 0;
}


/* App.js */
.outer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.main-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transform: translate(-150px, 0);
}

.route-container {
  width: 700px;
  background-color: rgba(255, 255, 255, 0.1);
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 2rem;
  padding-left: 10%;
  /* border: 0.5px solid gray; */
  border-radius: 10px;
  box-sizing: border-box;
  transform: translate(50%, 0);
  transform-origin: left center;
  box-shadow: 0 0 10px 0px black;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.heading {
  font-family: optima, sans-serif;
  /* letter-spacing: 0.5px; */
  /* font-style: italic; */
  font-weight: 700;
  font-size: 25px;
  color: var(--text-primary);
  border-bottom: 0.5px solid gray;
  padding-bottom: 5px;
}

/* Home.jsx */
.home-con {
  position: absolute;
  transform: translate(-50%, 0);
  transform-origin: right center;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px 0 black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 10px;
  z-index: 10;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
}

.home-img-con {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-img-con>.bg-img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  background-image: url("./static/images/skyline.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-img-con>.profile-photo {
  width: 130px;
  margin: 0 10px;
  border-radius: 50%;
  transform: translate(0, -30%);
  box-shadow: 0 0 10px var(--primary-color);
}

.name-con {
  margin: 0 20px;
  text-align: center;
}

.name-con>.name {
  margin: 0;
  padding: 0;

  font-family: Impact, Charcoal, sans-serif;
  font-family: optima, sans-serif;
  font-weight: 700;
  font-size: 35px;
}

.name-con>.tagline {
  color: var(--text-primary);
  font-weight: 500;
  border-bottom: 0.5px solid gray;
  margin-top: 0;
  padding: 20px;
  padding-top: 10px;
}

.social-con {
  text-align: center;
  margin: 10px 0;
}

.social-con>a {
  color: white;
  font-size: 30px;
  padding: 0 10px;
  margin: 0 10px;
}

.hire-con {
  text-decoration: none;
  text-align: center;
  margin: 10px 0 0 0;
  padding: 10px 0;
  color: var(--text-primary);
  border: 0.5px solid var(--text-primary);
  border-radius: 8px;
  transition: all 0.2s;
}

.hire-con:hover {
  background-color: var(--text-primary);
  color: var(--secondary-color);
}

.hire-con>i {
  margin: 0 5px;
}



/* About.jsx */
.about-con .about-desc{
  line-height: var(--line-space);
}
.stack-con {
  display: flex;
  flex-wrap: wrap;
}

.stack-element {
  width: 20%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0.8;
}

.stack-icon-con {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stack-icon {
  width: 40%;
}

/* .react{
  width: 35%;
}

.mongodb{
  width: 40%;
} */

.stack-title {
  font-size: 15px;
  font-weight: normal;
  opacity: 0.7;
}





/* Navigation Menu */
.nav-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  /* transform: scale(0.3), translate(-50%, 0); */
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 black;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(20px);
}

.nav-container>a {
  color: var(--text-primary);
  padding: 1rem;
  opacity: 0.4;
  transition: all 0.2s ease-out;
}

.nav-container>a:hover {
  opacity: 1;
}

.nav-container>a>li {
  list-style: none;
}

.scaled {
  scale: 1.2;
  opacity: 1 !important;
}





/* Projects, Work, Contribution */
.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-con {
  width: 48%;
  /* padding: 5px; */
  margin: 0 0 20px 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.project-img-con {
  width: 100%;
  height: 180px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-name {
  margin: 10px;
}

.project-desc {
  margin: 5px 10px;
  font-style: italic;
  font-weight: normal;
  opacity: 0.6;
}

.project-links {
  margin: 10px;
}

.project-links a {
  color: var(--text-primary);
}

.github-con,
.holopin.con {
  width: 100%;
}

.holopin-link img {
  width: 100%;
}

.github-link img {
  max-width: 100%;
}





/* Experience, Education */
.experiences a,
.universities a {
  color: white;
  font-style: italic;
}

.experience-con {
  margin-bottom: 30px;
}

.exp-name-year {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exp-name,
.exp-year {
  margin: 5px 0;
  padding: 0;
}

.exp-company {
  margin: 0;
  font-style: italic;
  font-weight: normal;
  opacity: 0.6;
}

.exp-desc {
  line-height: var(--line-space);
  list-style: circle;
}

.university {
  margin-bottom: 20px;
}


@media (max-width: 1150px) {
  .home-con {
    width: 300px;
  }

  .route-container {
    width: 600px;
  }
}

@media (max-width: 950px) {
  .home-con {
    width: 250px;
    transform: translate(-30%, 0) !important;
  }

  .home-con>.name-con>.name {
    font-size: 25px;
  }

  .home-con>.social-con>a {
    font-size: 25px;
  }

  .route-container {
    width: 500px;
    transform: translate(55%, 0) !important;
  }
}

/* Mobile Screens */
@media (max-width: 750px) {
  .outer {
    height: auto;
  }

  .main-container {
    flex-direction: column;
    transform: translate(0, 0);
    width: 100%;
  }

  /* Home */
  .home-con {
    transform: translate(0, 0) !important;
    width: 100%;
    position: static;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .home-img-con>.bg-img {
    height: 200px;
  }

  .home-con>.name-con>.name {
    font-size: 35px;
  }

  /* Navigation */
  .route-container {
    position: static;
    transform: translate(0, 0) !important;
    height: auto;
    width: 90%;
    margin: -10px 15px 80px 15px;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px 0;
  }

  /* About */
  .stack-element {
    width: 33%;
    flex: none;
    margin: 10px 0;
  }

  .stack-icon {
    width: 40%;
  }

  /* Experience, Education */
  .exp-name-year {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 500px) {

  /* Home */
  .name-con>.tagline {
    font-size: 18px;
  }

  /* About */
  .stack-element {
    width: 50%;
  }

  /* Porject/work */
  .project-con {
    width: 100%;
  }
}